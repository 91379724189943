body {
  background: rgb(228, 228, 228);
}

.App {
  text-align: center;
}

.App-logo {
  height: 312px;
  pointer-events: none;
}

.App-header {
  height: 312px;
  display: flex;
  background-image: url("./assets/header.png");
  background-position: center;
  background-size: auto;
}

.App-player-button-play {
  height: 100px;
  width: 100px;
  background-image: url("./assets/play.png");
  background-size: contain;
}

.App-player-button-stop {
  height: 100px;
  width: 100px;
  background-image: url("./assets/stop.png");
  background-size: contain;
}

.App-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.artwork{
  width:100%
}

.App-title{
  font-size: 15px;
}

@media only screen and (max-width: 800px) {
  .artwork{
    width:50%
  }
  .App-header {
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
